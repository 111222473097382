<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
export default {
  name: 'publicToilet',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/config/farmPublicToilet/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '地址',
          type: 'input',
          key: 'address'
        },
        {
          label: '',
          type: 'text',
          key: 'a1'
        }
      ]
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'lon',
          title: '位置',
          align: 'left',
          sorter: (a, b) => a.lon - b.lon,
          customRender: function(text, records) {
            return records.lat == null ? (
              ''
            ) : (
              <div>
                经度：{records.lon}
                &nbsp; &nbsp; &nbsp; &nbsp; 纬度：
                {records.lat}
              </div>
            )
          },
          onExport: (text, records) => {
            return `经度：${records.lon} 纬度：${records.lat}`
          }
        },
        {
          dataIndex: 'address',
          title: '地址',
          align: 'left',
          customRender: function(text, records) {
            return records.address == null ? '' : <div>{records.regions + ' ' + records.address}</div>
          },
          onExport: (text, records) => {
            return records.regions + ' ' + records.address
          }
        },

        {
          dataIndex: 'type',
          title: '类型',
          align: 'left',
          type: 'badge',
          filters: [
            {
              text: '星级',
              value: '1'
            },
            {
              text: '普通',
              value: '2'
            }
          ],
          onExport: records => {
            return ['星级', '普通'][Number(records) - 1]
          },
          filterMultiple: false,
          customRender: function(text, records) {
            return records.type == null ? '' : <div>{records.type == 1 ? '星级' : '普通'}</div>
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => this.$router.push(`/publicInfo/publicToiletDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPost.call(this, {
                    url: `/config/farmPublicToilet/delete?id=${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          icon: 'plus',
          type: 'primary',
          onClick: () => this.$router.push('/publicInfo/publicToiletDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/config/farmPublicToilet/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    },
    handleMapPreview() {
      return [
        {
          name: '地图预览',
          type: 'primary',
          onClick: () => {
            const markerList = this.records.map(e => ({
              lng: e.lon,
              lat: e.lat
            }))

            apiTool.openMapMarkers({
              title: '地图标记点',
              value: markerList
            })
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        rightButton={this.handleMapPreview()}
        records={this.records}
      />
    )
  }
}
</script>
